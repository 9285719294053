<template>
  <div>
    <template v-if="field.type === TYPES.USERS">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="modelValue"
            :items="field?.items || []"
            :rules="computedRules"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Select user"
            prepend-inner-icon="mdi-account"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.URL">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="modelValue"
            :rules="computedRules"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Enter URL"
            prepend-inner-icon="mdi-link"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.EMAIL">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="modelValue"
            :rules="computedRules"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Enter email"
            prepend-inner-icon="mdi-email-outline"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.TEXT_FIELD">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="modelValue"
            :rules="computedRules"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Enter text"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.LANGUAGE">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="modelValue"
            :items="field?.items || []"
            :rules="computedRules"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Select language"
            prepend-inner-icon="mdi-translate"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.NUMBER">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="modelValue"
            :rules="computedRules"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Enter number"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.PRICE">
      <v-row dense>
        <v-col cols="7" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="5" class="pb-0">
          <element-control-content-field-label :field="field">
            Currency
          </element-control-content-field-label>
        </v-col>
        <v-col cols="7">
          <v-text-field
            v-model="field['value']"
            clearable
            :rules="computedRules"
            hide-details="auto"
            dense
            outlined
            placeholder="Enter price"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
        <v-col cols="5">
          <v-autocomplete
            v-model="field['currency']"
            :items="field?.items || []"
            :rules="computedRules"
            item-text="iso"
            item-value="id"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Select currency"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.TEXTAREA">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="modelValue"
            :rules="computedRules"
            clearable
            hide-details="auto"
            dense
            outlined
            placeholder="Enter text"
            :error-messages="errors[field.id]"
            @change="errors[field.id] = []"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="field.type === TYPES.TIME">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <element-control-content-field-label :field="field">
            {{ field.name }}
          </element-control-content-field-label>
        </v-col>
        <v-col cols="12">
          <v-menu
            bottom
            content-class="my-shadow--e2"
            :close-on-content-click="false"
            max-width="318"
          >
            <template #activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="modelValue"
                :rules="computedRules"
                :value="modelValue"
                readonly
                clearable
                hide-details="auto"
                dense
                outlined
                placeholder="Select time"
                prepend-inner-icon="mdi-clock"
                :error-messages="errors[field.id]"
                @change="errors[field.id] = []"
              />
            </template>
            <v-card flat outlined rounded="lg" width="320">
              <v-card-text>
                <v-time-picker v-model="modelValue" format="24h" />
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div style="opacity: 0.5">
        Field type is not supported. ({{ field.type }})
      </div>
    </template>
  </div>
</template>

<script>
import { CONTENT_FIELD_TYPES } from "../defaults";
import ElementControlContentFieldLabel from "./ElementControlContentFieldLabel.vue";

export default {
  components: { ElementControlContentFieldLabel },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    validationResult: {
      type: Object,
      default: () => ({}),
    },
    value: {},
  },

  inject: ["filtersData", "filtersDataPendingKeys", "provideValidationResult"],

  data: () => ({
    TYPES: CONTENT_FIELD_TYPES,
    isDialogVisible: false,
  }),

  computed: {
    errors: {
      get() {
        return this.$store.getters[
          "site-worksheets/setContentFieldValidationResults"
        ]; // this.provideValidationResult();
      },
      set(value) {
        this.$emit("update:validationResult", value);
      },
    },
    computedRules() {
      const rules = [];

      if (this.field?.pivot?.is_required) {
        const requiredRule = (v) => !!v || "Field is required.";
        rules.push(requiredRule);
      }

      return rules;
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
