<template>
  <div>
    <template v-if="loading">
      <div class="skeleton" style="height: 120px; width: 100%" />
    </template>
    <template v-else-if="formValue?.info_fields?.length">
      <v-row dense class="d-flex flex-column">
        <template v-for="(field, idx) in computedVisibleInfoFields">
          <v-col v-if="field" cols="12" :key="`field-${idx}`" class="relative">
            <v-hover v-slot="{ hover }">
              <div>
                <element-control-info-field-factory
                  v-show="!field.isEditing"
                  :field="field"
                  :details="formValue?.fields"
                  v-model="field.value"
                />
                <div v-show="field.isEditing" class="px-4 py-1">
                  <element-control-field-factory
                    :field="field"
                    v-model="field.value"
                    :details="formValue?.fields"
                  />
                </div>
                <v-btn
                  small
                  @click="toggleEditing(field)"
                  v-show="hover"
                  class="px-2 text-normal"
                  style="min-width: 32px"
                  right
                  top
                  absolute
                  :color="field.isEditing ? 'success' : ''"
                >
                  {{ field.isEditing ? "Save" : "Edit" }}
                  <v-icon size="12" class="ml-2">{{
                    field.isEditing ? "mdi-content-save" : "mdi-pencil"
                  }}</v-icon>
                </v-btn>
              </div>
            </v-hover>
          </v-col>
          <v-divider
            v-if="idx !== computedVisibleInfoFields?.length - 1"
            :key="`field-divider-${idx}`"
            :details="formValue?.fields"
            v-model="field.value"
          />
        </template>
      </v-row>
    </template>
    <template v-else
      ><div
        class="text-center d-flex flex-column align-center pa-4"
        style="height: 100%; opacity: 0.5"
      >
        <v-icon>mdi-border-none-variant</v-icon>
        <small> There are no info fields... </small>
      </div>
    </template>
    <div
      v-if="computedContainSomeNotRequiredItems"
      class="px-2 pb-2 text-center"
    >
      <v-btn
        class="text-normal"
        :color="isShowMoreVisible ? '' : 'transparent'"
        @click="isShowMoreVisible = !isShowMoreVisible"
      >
        {{ isShowMoreVisible ? "Show less" : "Show more" }}
        <v-icon small right>
          {{ isShowMoreVisible ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ElementControlInfoFieldFactory from "./ElementControlInfoFieldFactory.vue";
import ElementControlFieldFactory from "./ElementControlFieldFactory.vue";
import { CONTENT_FIELD_TYPES } from "../defaults";

export default {
  props: {
    formValue: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ElementControlFieldFactory,
    ElementControlInfoFieldFactory,
  },

  data: () => ({
    isShowMoreVisible: false,
    isEditing: false,
  }),

  methods: {
    async toggleEditing(field) {
      if (!("value" in field)) await this.initializeFieldDefaultValue(field);

      // If items is undefined AND items exists in details
      if (
        !("items" in field) &&
        this.getFieldValueFromDetails(field)?.items?.length > 0
      )
        await this.initializeFieldItems(field);

      if (field.isEditing === undefined || !field.isEditing) {
        return this.$set(field, "isEditing", true);
      }
      this.$set(field, "isEditing", false);
    },
    async initializeFieldDefaultValue(field) {
      try {
        return new Promise((resolve) => {
          const details = this.getFieldValueFromDetails(field);

          this.setDefaultValueToField(field, details);

          resolve();
        });
      } catch {
        console.error("Error while initializing fieldValue.", field);
      }
    },
    setDefaultValueToField(field, details) {
      if (field.type === CONTENT_FIELD_TYPES.PRICE) {
        this.$set(field, "value", details?.value?.value);
        this.$set(field, "currency", details?.value?.currency);
        return;
      }

      this.$set(field, "value", details?.value);
    },
    async initializeFieldItems(field) {
      try {
        return new Promise((resolve) => {
          const details = this.getFieldValueFromDetails(field);

          if (details?.items) this.$set(field, "items", details.items);

          resolve();
        });
      } catch {
        console.error("Error while initializing fieldItems.", field);
      }
    },
    getFieldValueFromDetails(field) {
      return this.formValue?.fields[field.id];
    },
  },

  computed: {
    computedContainSomeNotRequiredItems() {
      return this.formValue.info_fields.some(
        (item) => !item?.pivot?.is_required
      );
    },
    computedInfoFields() {
      return this.formValue.info_fields?.toSorted(
        (a, b) => a?.pivot?.order - b?.pivot?.order
      );
    },
    computedVisibleInfoFields() {
      if (this.isShowMoreVisible) {
        return this.computedInfoFields;
      }

      return this.computedInfoFields.filter((item) => item?.pivot?.is_required);
    },
  },
};
</script>
